<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              {{ $t("user_group.users") }}
            </li>
            <li>{{ $t("user_group.title") }}</li>
          </ul>
        </nav>
      </div>

      <div v-if="errorMessages.length" class="uk-alert-danger" uk-alert>
        <template v-for="(error, index) in errorMessages">
          <span :key="index" v-if="error != null">
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red" class="m-0">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </span>
        </template>
      </div>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ $t("user_group.title") }} </span>
          </h2>
        </div>
        <div class="section-header-right">
          <a href="javascript:void(0)" @click="$router.push({ name: 'company.user.group.add' })" class="btn btn-default"
            type="button">
            <i class="uil-plus"></i>
            {{ $t("user_group.create") }}
          </a>
        </div>
      </div>

      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <!-- <div>
              <a @click="filterResult" href="javascript:void(0)" class="btn btn-icon btn-hover btn-circle"
                 aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0"
                   style="left: 91px; top: 159.297px;">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small" type="search" @change="filterResult"
                         v-model="filter.search" :placeholder="$t('user_group.list.search')" autofocus="">
                </span>
              </div>
            </div> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" @input="filterResult" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div ref="filterRef" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li class="uk-active">
                    <a href="javascript:void(0)" @click="sortOrder('name', 'asc')">{{ $t("filters.order.a_z") }}</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="sortOrder('name', 'desc')">{{ $t("filters.order.z_a") }}</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="sortOrder('created_at', 'asc')">
                      {{ $t("filters.order.created_at_asc") }}</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="sortOrder('created_at', 'desc')">
                      {{ $t("filters.order.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>

              <!-- Export -->
              <a href="javascript:void(0)" @click="exportList()" class="btn btn-icon btn-hover  btn-circle"
                :uk-tooltip="$t('filters.export_excell')" title="" aria-expanded="false">
                <i class="fa-file-excel fa"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Table -->
        <default-loading v-if="group_loading"></default-loading>
        <template v-else>
          <div class="table-responsive">
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t("user_group.list.name") }}</th>
                  <th scope="col">{{ $t("user_group.list.user_count") }}</th>
                  <th scope="col">{{ $t("general.created_at") }}</th>
                  <th scope="col">{{ $t("general.status") }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="item in formattedItem">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <div class="avatar-parent-child" style="width: max-content">
                          <i class="icon-feather-users"></i>
                          <span class="avatar-child avatar-badge bg-success"></span>
                        </div>
                      </div>
                      <div class="media-body ml-4">
                        <a @click="
                          $router.push({
                            name: 'company.user.group.edit',
                            params: { id: item.id },
                          })
                          " class="name h6 mb-0 text-sm">{{ item.name }}</a>
                      </div>
                    </div>
                  </th>
                  <td><i class="uil-user"></i> {{ item.count }}</td>
                  <td>
                    <i class="uil-calendar-alt"></i> {{ item.created_at }}
                  </td>
                  <td>
                    <div v-if="item.status_id">
                      <i class="uil-check"></i>
                      {{ $t("user_group.list.active") }}
                    </div>
                    <div v-else>
                      {{ $t("user_group.list.passive") }}
                    </div>
                  </td>
                  <td class="text-right">
                    <!-- Actions -->
                    <div class="actions ml-3">
                      <a @click="
                        $router.push({
                          name: 'company.user.group.edit',
                          params: { id: item.id },
                        })
                        " class="btn btn-icon btn-hover btn-sm btn-circle"
                        :uk-tooltip="$t('user_group.add_user_group')">
                        <i class="uil-user-plus"></i>
                      </a>
                      <a uk-toggle="target: #group_assignment_modal" @click="openAssignmentModal(item.id, item.count)"
                        type="button" class="btn btn-icon btn-hover btn-sm btn-circle"
                        :uk-tooltip="$t('general.assign_to_group')">
                        <i class="uil-location-arrow text-success"></i>
                      </a>
                      <a @click="
                        $router.push({
                          name: 'company.user.group.edit',
                          params: { id: item.id },
                        })
                        " class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.update_group')">
                        <i class="uil-pen "></i>
                      </a>
                      <a v-if="item.status_id" @click="statusChange(item.id, 0)"
                        class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.do_passive')" title=""
                        aria-expanded="false">
                        <i class="uil-minus-circle text-warning"></i>
                      </a>
                      <a v-else @click="statusChange(item.id, 1)" class="btn btn-icon btn-hover btn-sm btn-circle"
                        :uk-tooltip="$t('general.do_active')" title="" aria-expanded="false">
                        <i class="uil-check text-success"></i>
                      </a>
                      <a @click="removeItem(item.id)" class="btn btn-icon btn-hover btn-sm btn-circle"
                        :uk-tooltip="$t('general.remove')">
                        <i class="uil-trash "></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="group_assignment_modal" class="uk-flex-top" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical">
              <button class="uk-modal-close-default" type="button" uk-close></button>
              <div class="uk-modal-header">
                <h2 class="uk-modal-title">Atama Seç</h2>
              </div>
              <div class="card-header actions-toolbar border-0">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="row" style="width:100%">
                    <div class=" col-md-1  search-icon ">
                      <i class="uil-search"></i>
                    </div>
                    <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                      <input class="uk-search-input shadow-0 uk-form-small" type="search"
                        v-model="filterAssignment.search" :placeholder="$t('general.search')"
                        @input="getAssignmentItem(null)" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <!-- Shorting -->
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                      :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                      <i class="uil-list-ui-alt"></i>
                    </a>
                    <div ref="filterRef" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                      class="uk-dropdown">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li class="uk-active">
                          <a href="javascript:void(0)" @click="sortOrderAssignment('name', 'asc')">{{
                            $t("filters.order.a_z") }}</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="sortOrderAssignment('name', 'desc')">{{
                            $t("filters.order.z_a")
                          }}</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="sortOrderAssignment('created_at', 'asc')">
                            {{ $t("filters.order.created_at_asc") }}</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" @click="sortOrderAssignment('created_at', 'desc')">
                            {{ $t("filters.order.created_at_desc") }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-modal-body" style="max-height: 500px;overflow-y: scroll;padding-top: 0;">
                <default-loading v-if="assignment_loading"></default-loading>
                <template v-else>
                  <div class="uk-alert-warning" uk-alert v-if="userCount == 0">
                    <p>Grupta Kullanıcı Bulunmuyor.</p>
                  </div>
                  <table class="uk-table uk-table-middle uk-table-divider" v-else-if="assignments.length != 0">
                    <thead>
                      <tr>
                        <th>Atama İsmi </th>
                        <th>{{ $t('ajadam.end_date') }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(assignment, index) in formattedItemAssignment" :key="index">
                        <td>{{ assignment.name }}</td>
                        <td>{{ assignment.end_date }}</td>
                        <td class="text-right">
                          <button @click="assignmentCreate(assignment.id)" class="uk-button uk-button-default"
                            type="button">
                            {{ $t('menu.company_dashboard.create_assignment') }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="uk-alert-warning" uk-alert v-else>
                    <p>Atama yapılabilecek kayıt bulunmuyor.</p>
                  </div>
                  <pagination class="mt-2 mb-3" :pagination="paginationAssignment"
                    @changePage="setPageAssignment($event)"></pagination>
                </template>
              </div>
              <div class="uk-modal-footer d-flex justify-content-end">
                <a href="javascript:;" class="btn btn-danger uk-modal-close"> {{ $t("general.close") }}</a>
              </div>
            </div>
          </div>
          <pagination class="mt-2 mb-3" :pagination="pagination" @changePage="setPage($event)"></pagination>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ERROR,
  UPDATE_ITEM,
  ITEMS,
  LOADING,
  SUCCESS,
  DELETE_ITEM,
} from "@/core/services/store/user_group.module";
import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import assignment_module, {
  GET_ITEM as GET_ASSIGNMENT,
  ITEM as ASSIGNMENT_ITEM,
  MODULE_NAME as ASSIGNMENT_MODULE,
} from "@/core/services/store/assignment.module";
import Swal from "sweetalert2";

export default {
  name: "UserGroup",
  components: { Pagination, DefaultLoading },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(ASSIGNMENT_MODULE, assignment_module);
  },
  data() {
    return {
      page: 1,
      pageAssignment: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      activeGroup: null,
      userCount: null,
      filter: {
        search: null,
        order_by: null,
        sort: null,
      },
      filterAssignment: {
        search: null,
        order_by: null,
        sort: null,
      },
      pagination: null,
      paginationAssignment: null,
      assignment_loading: null,
      headers: this.apiHeaders,
      successMessage: null,
      errorMessages: [],
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    assignments: {
      get() {
        return store.getters[ASSIGNMENT_MODULE + "/" + ASSIGNMENT_ITEM];
      },
      set(value) { },
    },
    group_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[MODULE_NAME + "/" + SUCCESS];
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];

      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        this.pagination = this.items;
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            end_date: currentItem.end_date,
            status_id: currentItem.status,
            count: currentItem.count,
            created_at: currentItem.created_at
              ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
              : "-",
          });
        }
      }
      return results;
    },
    formattedItemAssignment() {
      let results = [];
      if (this.assignments === null || !("data" in this.assignments)) return [];

      for (let i = 0; i < this.assignments.data.length; i++) {
        let currentItem = this.assignments.data[i];
        this.paginationAssignment = this.assignments;
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            end_date: currentItem.end_date,
            status_id: currentItem.status,
            count: currentItem.count,
            created_at: currentItem.created_at
              ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
              : "-",
          });
        }
      }
      return results;
    },
  },
  methods: {
    statusChange(id, status_id) {
      store.dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
        url: BASE_URL + "/status/" + id,
        contents: {
          group: id,
          status_id: status_id,
        },
      });
    },
    assignmentCreate(assignmentId) {
      this.assignment_loading = true;
      axios
        .get(
          `/api/attach-group-assignment/${this.activeGroup}/${assignmentId}`,
          {
            headers: this.headers,
          }
        )
        .then(() => {
          this.getAssignmentItem(this.activeGroup);
          this.assignment_loading = false;
        });
    },
    openAssignmentModal(groupId, userCount) {
      this.activeGroup = groupId;
      this.userCount = userCount;
      this.getAssignmentItem(groupId);
      this.assignment_loading = false;
    },
    deleteUserGroup(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: BASE_URL + "/" + id,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.filterResult();
          } else {
            this.errorMessages.push(this.error);
          }
        });
    },
    removeItem(id) {
      let self = this;
      Swal.fire({
        title: "Bu grubu silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteUserGroup(id);
        }
      });
      return false;
    },
    getAssignmentItem(groupId) {
      if (!groupId)
        groupId = this.activeGroup;
      store.dispatch(ASSIGNMENT_MODULE + "/" + GET_ASSIGNMENT, {
        url: `api/group-assignments/${groupId}`,
        filters: {
          like: this.filterAssignment.search,
          page: this.pageAssignment,
          per_page: this.per_page,
          order: this.filterAssignment.sort,
          sort: this.filterAssignment.order_by,
          isProvider : true,
        },
      }).then((response) => {
      });
    },
    filterResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          like: this.filter.search,
          page: this.page,
          per_page: this.per_page,
          order_by: this.filter.order_by,
          sort: this.filter.sort,
        },
      });
    },
    sortOrder(orderBy, sort) {
      this.page = 1;
      this.filter.order_by = orderBy;
      this.filter.sort = sort;
      this.filterResult();
      UIkit.dropdown(this.$refs["filterRef"]).hide(0);
    },
    sortOrderAssignment(orderBy, sort) {
      this.pageAssignment = 1;
      this.filterAssignment.order_by = orderBy;
      this.filterAssignment.sort = sort;
      this.getAssignmentItem(null);
      // UIkit.dropdown(this.$refs["filterRef"]).hide(0);
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    setPageAssignment(page) {
      this.pageAssignment = page;
      this.getAssignmentItem(null);
    },
    exportList(
      status_id = null,
      url = "/api/group/export",
      file_name = "group_list_"
    ) {
      let self = this;
      let params = [];

      if (status_id != null) params = { status_id: status_id };

      let config = {
        responseType: "blob",
        headers: this.apiHeaders,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_APP_URL + url, params, config)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = file_name + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
        });
    },
  },
  mounted() {
    this.filterResult();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#group_assignment_modal");
    if (modal) modal.$destroy(true);
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-table th {
  text-transform: none !important
}
</style>
